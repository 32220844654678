import React from 'react';
import { ArrowRight, BarChart2, Brain, Database, Zap, Target } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const HomePage = () => {
  return (
    <div className="bg-gradient-to-br from-blue-50 via-white to-gray-100 dark:from-gray-900 dark:via-gray-900 dark:to-purple-950 text-gray-800 dark:text-gray-200 font-['Inter',sans-serif]">
      <main className="container mx-auto px-4 py-8">
        <HeroSection />
        <ServicesSection />
        <AIJourneySection />
        <TestimonialsSection />
        <CTASection />
      </main>
    </div>
  );
};

const HeroSection = () => (
  <section className="mb-12 text-center">
    <motion.h1 
      className="text-4xl md:text-5xl font-bold mb-6 inline-block"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-teal-600 dark:from-blue-400 dark:to-teal-400">
        Empowering Business
      </span>
      <br />
      <span className="text-gray-800 dark:text-gray-200">
        Through Data Intelligence
      </span>
    </motion.h1>
    <motion.p 
      className="text-lg md:text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto mb-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.4, duration: 0.8 }}
    >
      Unlock the potential of your data with our cutting-edge AI solutions. 
      We transform complex information into actionable insights, driving your business forward.
    </motion.p>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.8, duration: 0.8 }}
    >
      <Link to="/contact" className="bg-blue-600 dark:bg-blue-700 text-white px-6 py-3 md:px-8 md:py-4 rounded-full hover:bg-blue-700 dark:hover:bg-blue-600 transition-colors inline-flex items-center text-lg font-semibold shadow-lg hover:shadow-xl">
        Discover AI Solutions
        <Zap className="ml-2 w-5 h-5" />
      </Link>
    </motion.div>
  </section>
);

const ServicesSection = () => (
  <section className="mb-16">
    <h2 className="text-2xl md:text-3xl font-semibold mb-8 text-center">Our Expertise</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <ServiceCard 
        icon={<Database className="w-10 h-10 md:w-12 md:h-12 text-blue-500 dark:text-blue-400" />}
        title="Data Science"
        description="Uncover hidden patterns and drive strategic decision-making with our advanced data analysis techniques."
      />
      <ServiceCard 
        icon={<Brain className="w-10 h-10 md:w-12 md:h-12 text-teal-500 dark:text-teal-400" />}
        title="Machine Learning"
        description="Implement self-improving algorithms that adapt to your business needs and optimize operations."
      />
      <ServiceCard 
        icon={<BarChart2 className="w-10 h-10 md:w-12 md:h-12 text-green-500 dark:text-green-400" />}
        title="Data Engineering"
        description="Build robust, scalable data infrastructures that form the backbone of your AI initiatives."
      />
    </div>
  </section>
);

const ServiceCard = ({ icon, title, description }) => (
  <motion.div 
    className="bg-white dark:bg-gray-800 dark:bg-opacity-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className="mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-700 dark:text-gray-300">{description}</p>
  </motion.div>
);

const AIJourneySection = () => (
  <section className="mb-24">
    <h2 className="text-3xl font-semibold mb-12 text-center">Your AI Journey</h2>
    <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-4">
      <JourneyStep 
        icon={<Target className="w-10 h-10 text-blue-600 dark:text-blue-400" />}
        title="Define"
        description="We work with you to identify key business challenges and opportunities for AI integration."
        delay={0.2}
      />
      <motion.div 
        className="hidden md:block"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <ArrowRight className="w-8 h-8 text-gray-400 dark:text-gray-600" />
      </motion.div>
      <JourneyStep 
        icon={<Brain className="w-10 h-10 text-teal-600 dark:text-teal-400" />}
        title="Develop"
        description="Our experts design and implement tailored AI solutions to address your specific needs."
        delay={0.6}
      />
      <motion.div 
        className="hidden md:block"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <ArrowRight className="w-8 h-8 text-gray-400 dark:text-gray-600" />
      </motion.div>
      <JourneyStep 
        icon={<Zap className="w-10 h-10 text-green-600 dark:text-green-400" />}
        title="Deploy"
        description="We seamlessly integrate AI into your existing systems and provide ongoing support."
        delay={1}
      />
    </div>
  </section>
);

const JourneyStep = ({ icon, title, description, delay }) => (
  <motion.div 
    className="flex flex-col items-center text-center max-w-xs"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    whileHover={{ scale: 1.05 }}
  >
    <motion.div 
      className="mb-4 p-4 bg-gray-100 dark:bg-gray-800 dark:bg-opacity-50 rounded-full shadow-lg"
      whileHover={{ rotate: 360 }}
      transition={{ duration: 0.5 }}
    >
      {icon}
    </motion.div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 dark:text-gray-400">{description}</p>
  </motion.div>
);

const TestimonialsSection = () => {
  const testimonials = [
    {
      quote: "Our AI-driven predictive maintenance model has reduced downtime by 35% and saved us millions.",
      author: "Sarah Johnson, CTO of IndustrialTech",
    },
    {
      quote: "The customer insights we've gained through AI have transformed our marketing strategy and boosted ROI by 50%.",
      author: "Michael Chen, CMO of E-commerce Giants",
    },
    // You can add more testimonials here as needed
  ];

  return (
    <section className="mb-24">
      <h2 className="text-3xl font-semibold mb-12 text-center">Client Success Stories</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard 
            key={index}
            quote={testimonial.quote}
            author={testimonial.author}
          />
        ))}
      </div>
    </section>
  );
};

const TestimonialCard = ({ quote, author }) => (
  <motion.div 
    className="bg-gray-100 dark:bg-gray-800 dark:bg-opacity-50 p-6 rounded-lg shadow-md"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.6 }}
  >
    <p className="text-lg italic mb-4 text-gray-800 dark:text-gray-200">"{quote}"</p>
    <p className="font-semibold text-gray-900 dark:text-gray-100">- {author}</p>
  </motion.div>
);

const CTASection = () => (
  <section className="text-center bg-gradient-to-r from-blue-500 to-teal-500 dark:from-blue-700 dark:to-purple-800 text-white p-12 rounded-lg shadow-xl">
    <h2 className="text-3xl font-bold mb-4">Ready to Revolutionize Your Business?</h2>
    <p className="text-xl mb-8">Let's harness the power of AI to drive innovation and achieve unprecedented growth.</p>
    <Link to="/contact" className="bg-white text-blue-600 dark:text-purple-700 px-8 py-4 rounded-full hover:bg-gray-100 transition-colors inline-flex items-center text-lg font-semibold">
      Start Your AI Transformation
      <ArrowRight className="ml-2 w-5 h-5" />
    </Link>
  </section>
);

export default HomePage;