import React from 'react';
import { Star, ArrowRight } from 'lucide-react';

const CaseStudies = () => {
  return (
    <div className="bg-white dark:bg-gray-900 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-center mb-12 text-gray-900 dark:text-white">Our Success Stories</h1>
        
        <section className="mb-16">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-200">Client Reviews</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3].map((review) => (
              <div key={review} className="bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <div className="flex items-center mb-4">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star key={star} className="w-5 h-5 text-yellow-400 fill-current" />
                  ))}
                </div>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                  "AIF transformed our business with their cutting-edge AI solutions. Highly recommended!"
                </p>
                <p className="font-semibold text-gray-900 dark:text-white">- Happy Client {review}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-16">
          <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-200">Project Showcase</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[1, 2].map((project) => (
              <div key={project} className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
                <div className="h-64 bg-gray-300 dark:bg-gray-700"></div>
                <div className="p-6">
                  <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">AI-Powered Predictive Analytics</h3>
                  <p className="text-gray-700 dark:text-gray-300 mb-4">
                    We implemented a state-of-the-art predictive analytics system for a Fortune 500 company, resulting in a 30% increase in operational efficiency.
                  </p>
                  <a href="#" className="text-blue-600 dark:text-blue-400 inline-flex items-center">
                    Learn more <ArrowRight className="ml-2 w-4 h-4" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-gray-200">Case Studies</h2>
          <div className="space-y-8">
            {[1, 2].map((caseStudy) => (
              <div key={caseStudy} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">
                  Revolutionizing Customer Service with AI Chatbots
                </h3>
                <p className="text-gray-700 dark:text-gray-300 mb-4">
                  Learn how we helped a leading e-commerce platform improve customer satisfaction by 40% and reduce support costs by 50% through the implementation of AI-powered chatbots.
                </p>
                <a href="#" className="text-blue-600 dark:text-blue-400 inline-flex items-center">
                  Read full case study <ArrowRight className="ml-2 w-4 h-4" />
                </a>
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default CaseStudies;